
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

type Props = {
  size?: number | string;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning",
};
export default function Loader(props: Props) {
  const { color, size } = props;
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress color={color} size={size} />
    </Box>
  );
}
